<template>
  <KTCard :page="page">
    <template #toolbar>
      <KTAddButton :to="addRoute" v-if="!finalizado" />
    </template>
    <template #body>
      <KTTable
        :fields="depreciacoes.columns"
        :items="depreciacoes.data"
        :primaryKey="depreciacoes.primaryKey"
        :sortBy="depreciacoes.sortBy"
      >
        <template #cell(_actions)="{ item }">
          <KTViewMiniButton
            :to="getEditRoute(item.idpip_investimento_depreciacao)"
            v-if="finalizado"
          />
          <KTEditMiniButton
            class="mr-3"
            :to="getEditRoute(item.idpip_investimento_depreciacao)"
            v-else
          />
          <KTRemoveMiniButton
            @click="ApiRemoveItem(item.idpip_investimento_depreciacao)"
            v-if="!finalizado"
          />
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipDepreciacaoList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-dollar-sign",
        title: "Depreciação",
        description: `A perda de valor de um bem no decorrer do tempo
          é chamada de depreciação. Essa desvalorização pode ser decorrente
          do desgaste natural, do uso ou ainda de sua obsolescência. Alguns
          exemplos de bens que sofrem perda de valor são as infraestruturas,
          máquinas, equipamentos e veículos. É a Receita Federal quem
          determina qual a vida útil estimada de um bem e, com base nela,
          sua taxa anual de depreciação. Os valores cadastrados aqui na PAEF
          seguem essa determinação, portanto não é necessário altera-los. Se
          necessário você pode cadastrar outra categoria.`
      },
      depreciacoes: {
        columns: [
          {
            key: "_actions",
            label: "Ações",
            sortable: false,
            tdClass: "text-center"
          },
          {
            key: "descricao_tipo",
            label: "Tipo",
            sortable: true
          },
          {
            key: "percentual_depreciacao",
            label: "Depreciação Anual (%)",
            sortable: true,
            tdClass: "text-right",
            formatter: "number"
          }
        ],
        data: [],
        primaryKey: "idpip_investimento_depreciacao",
        sortBy: "descricao_tipo"
      },
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/depreciacao`;
    },
    manual() {
      return {
        session: "depreciacao",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    addRoute() {
      return {
        name: "pip_depreciacao_new",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.depreciacoes.data = res[R_GETLIST].depreciacoes;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    getEditRoute(id) {
      return {
        name: "pip_depreciacao_edit",
        params: {
          idplano_investimento: this.idplano_investimento,
          idpip_investimento_depreciacao: id
        }
      };
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
